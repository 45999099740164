import React, { useState } from "react";
import loginBackground from "../../assets/login-background.png";
import loginLogo from "../../assets/login-logo.png";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import cx from "classnames";
import styles from "./Login.module.scss";
import Terms from "./Terms";
import LoginForm from "../LoginForm/LoginForm";
import RegisterForm from "../RegisterForm/RegisterForm";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

const Login = ({ updateToken }) => {
  const [view, setView] = useState("login");
  const [terms, setTerms] = useState(false);

  const isStaging = process.env.REACT_APP_ENV === "staging";

  return (
    <div className={styles.screenContainer}>
      <img className={styles.background} alt="EkoVAV" src={loginBackground} />
      <TransitionGroup>
        <CSSTransition key={view} timeout={300} classNames="login-animation">
          <div className={styles.loginContainer}>
            <div className={cx(styles.logo, styles.flexY)}>
              <img src={loginLogo} alt="EkoVAV" />
              {isStaging && <p>staging</p>}
            </div>
            {view === "login" && (
              <LoginForm setView={setView} updateToken={updateToken} />
            )}
            {view === "register" && (
              <RegisterForm setView={setView} setTerms={setTerms} />
            )}
            {view === "forgot-password" && <ForgotPassword setView={setView} />}
            {view === "resend-confirmation" && (
              <ForgotPassword setView={setView} resend={true} />
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
      {terms && <Terms setTerms={setTerms} />}
    </div>
  );
};

export default Login;
