import React from "react";
import { Route } from "react-router-dom";

import PageContainer from "../components/PageContainer/PageContainer";
import OpenChallenges from "../components/Challenges/OpenChallenges";
import CompletedChallenges from "../components/Challenges/CompletedChallenges";

const Challenges = ({
  pageContext,
  activeTabIndex,
  challengesHistory,
  setChallengesHistory,
}) => {
  const tabs = {
    active: activeTabIndex,
    page: pageContext.page,
    color: pageContext.color,
    tabs: [
      {
        to: `${pageContext.page}/open`,
        label: "Avoimet haasteet",
      },
      {
        to: `${pageContext.page}/completed`,
        label: "Suoritetut haasteet",
      },
    ],
  };

  return (
    <PageContainer tabs={tabs}>
      <Route exact path={`${pageContext.page}/open`}>
        <OpenChallenges />
      </Route>
      <Route exact path={`${pageContext.page}/completed`}>
        <CompletedChallenges
          challengesHistory={challengesHistory}
          setChallengesHistory={setChallengesHistory}
        />
      </Route>
    </PageContainer>
  );
};

export default Challenges;
