import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Checkbox, InputError, TextInput } from "../Inputs/Inputs";
import SecurityContext from "../Context/SecurityContext";
import Spinner from "../Spinner/Spinner";
import { redirectUrl } from "../../helpers/helpers";
import styles from "../Login/Login.module.scss";
import { complexSelectStyles } from "../../helpers/stylesHelper";

export default function RegisterForm(props) {
  const { setView, setTerms } = props;
  const { api } = useContext(SecurityContext);
  let history = useHistory();

  const [complexes, setComplexes] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setLoading(true);
      api
        .getComplexes()
        .then(response => {
          if (response && response.data) {
            const list = response.data.map(item => {
              return {
                label: item.identifier + " " + item.name,
                addresses: item.addresses,
                value: item.identifier,
              };
            });
            setComplexes(list);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Oltava vähintään 3 merkkiä")
      .max(20, "Voi olla korkeintaan 20 merkkiä")
      .required("Pakollinen tieto"),
    email: Yup.string()
      .email("Sähköpostiosoite on virheellinen")
      .required("Pakollinen tieto"),
    password: Yup.string()
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Salasanat eivät täsmää")
      .required("Pakollinen tieto"),
    complex: Yup.string().required("Pakollinen tieto"),
  });

  async function handleRegister(values, { setSubmitting, setFieldError }) {
    const redirect_url = await redirectUrl("/note");
    setSubmitting(true);

    if (values.email && values.password) {
      const credentials = {
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        confirm_success_url: redirect_url,
        username: values.username,
        building_complex_identifier: values.complex,
      };

      api
        .registerUser(credentials)
        .then(({ data }) => {
          setSubmitting(false);
          if (data && data.id) {
            history.push("/note", {
              title: "Kiitos rekisteröitymisestäsi",
              msg:
                "Saat pian sähköpostiisi ohjeet sähköpostiosoitteesi varmentamiseen.",
            });
          }
        })
        .catch(data => {
          setSubmitting(false);
          if (data && data.errors) {
            const formKeys = Object.keys(values);
            Object.keys(data.errors).forEach(err => {
              if (formKeys.includes(err)) {
                setFieldError(err, data.errors[err]);
              } /*else if (err === "full_messages") {

              }*/
            });
          }
        });
    } else {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          complex: "",
          email: "",
          username: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleRegister}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => {
          const noErrors =
            !errors.username &&
            !errors.email &&
            !errors.password &&
            !errors.password_confirmation &&
            !errors.complex;
          return (
            <Form className={styles.form}>
              <span className={styles.complexLabel}>Asuinkohde</span>
              <Select
                name="complex"
                options={complexes || []}
                components={{ Option }}
                filterOption={customFilter}
                styles={complexSelectStyles}
                placeholder="Hae nimellä tai osoitteella"
                onChange={selected => {
                  setFieldValue("complex", selected.value);
                }}
              />
              {loading ? <Spinner>Ladataan</Spinner> : null}
              {errors.complex ? (
                <InputError>{errors.complex}</InputError>
              ) : null}
              <TextInput
                formik
                required
                type="email"
                name="email"
                label="Sähköposti"
              />
              <ErrorMessage name="email" component={InputError} />
              <TextInput
                formik
                required
                name="username"
                label="Nimimerkki"
              />
              <ErrorMessage name="username" component={InputError} />
              <TextInput
                formik
                required
                type="password"
                name="password"
                label="Salasana"
              />
              <ErrorMessage name="password" component={InputError} />
              <TextInput
                formik
                required
                type="password"
                name="password_confirmation"
                label="Salasanan vahvistus"
              />
              <ErrorMessage
                name="password_confirmation"
                component={InputError}
              />
              <div className={styles.checkboxContainer}>
                <Checkbox
                  formik
                  required
                  id="agreeToTerms"
                  name="agreeToTerms"
                  label={
                    <div>
                      Hyväksyn palvelun{" "}
                      <button
                        onClick={e => {
                          e.preventDefault();
                          setTerms(true);
                        }}
                        className={styles.viewTerms}
                      >
                        käyttöehdot
                      </button>
                    </div>
                  }
                />
              </div>
              <div className={styles.submit}>
                <Button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.complex ||
                    !values.email ||
                    !values.username ||
                    !values.password ||
                    !values.password_confirmation ||
                    !values.agreeToTerms ||
                    !noErrors
                  }
                >
                  Luo tili
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div
        className={styles.goToView}
        onClick={() => setView("resend-confirmation")}
      >
        <span>Lähetä vahvistus uudelleen</span>
      </div>
      <div className={styles.switchViewLabel}>
        <span>Onko sinulla jo tili?</span>
      </div>
      <Button grey onClick={() => setView("login")}>
        Kirjaudu sisään
      </Button>
    </>
  );
}

const Option = props => {
  const addresses = props.data.addresses && props.data.addresses.join(", ");
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <div style={{ fontSize: 12 }}>{addresses}</div>
    </components.Option>
  );
};

const customFilter = (option, searchText) => {
  if (!searchText) return true;
  const label = option.data.label && option.data.label.toLowerCase();
  const addresses = option.data.addresses && option.data.addresses.join(", ");
  if (
    label.includes(searchText.toLowerCase()) ||
    addresses.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};
