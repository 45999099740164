import React from "react";
import styles from "./Terms.module.scss";

function Terms({ setTerms }) {
  return (
    <div className={styles.termsOfService}>
      <div className={styles.closeTerms}>
        <button onClick={() => setTerms(false)}>Sulje</button>
      </div>
      <div className={styles.termsOfServiceInner}>
        <h1>Palvelun käyttöehdot</h1>
        <h2>1. Sovelluksen tarjoaja</h2>
        <p>EkoVAV-sovelluksen tarjoaa käyttöösi ja sitä ylläpitää VAV.</p>
        <p>
          <table>
            <tr>
              <td>Y-tunnus:</td>
              <td>2903526-6</td>
            </tr>
            <tr>
              <td>Osoite:</td>
              <td>www.vav.fi</td>
            </tr>
            <tr>
              <td>Sähköposti:</td>
              <td>
                <a href="mailto:viestinta@vav.fi">viestinta@vav.fi</a>
              </td>
            </tr>
          </table>
        </p>

        <h2>2. Sinun ja VAV:n välinen sopimussuhde</h2>
        <h3>2.1. Sopimuksen syntyminen</h3>
        <p>
          Nämä käyttöehdot koskevat EkoVAV-sovelluksen käyttöä ja siinä
          esitettyä sisältöä.
        </p>
        <p>
          Kun käytät sovellustamme, nämä ehdot muodostavat sopimuksen sinun eli
          sovelluksen käyttäjän ja VAV:n välille. Sinulla on oikeus käyttää
          sovellusta vain sitoutumalla noudattamaan näitä käyttöehtoja ja
          sovelluksessa mahdollisesti erikseen annettavia ohjeita.
        </p>
        <p>
          Sovelluksen käyttäminen edellyttää rekisteröitymistä. Sitoudut
          antamaan totuudenmukaiset ja täydelliset tiedot rekisteröityessäsi
          sovelluksen käyttäjäksi sekä pitämään nämä tiedot ajan tasalla.
        </p>
        <p>
          Rekisteröidyt sovellukseen sähköpostiosoitteellasi ja valitsemallasi
          salasanalla. Sinun on vahvistettava rekisteröitymisesi sähköpostiisi
          lähetettävän linkin kautta.
        </p>
        <p>
          Olet (ja sinun vanhempasi tai laillinen huoltajasi on, jos olet alle
          13-vuotias) vastuussa kaikesta omien rekisteröitymistietojesi avulla
          tapahtuvasta sovelluksen käytöstä sekä puhelimesi tai muun laitteesi
          asianmukaisen tietoturvan järjestämisestä. Sitoudut suojaamaan
          rekisteröitymistietosi väärinkäytöksiltä ja ilmoittamaan meille heti
          mahdollisista väärinkäytöksistä.
        </p>

        <h3>2.2. Ikäraja</h3>
        <p>
          Sinun tulee olla 13-vuotias tai vanhempi, jotta voit itse
          rekisteröityä EkoVAV-sovellukseen ja käyttää sitä.
        </p>
        <p>
          Jos olet alle 13-vuotias, voit käyttää sovellusta vain, jos vanhempasi
          tai laillinen huoltajasi on antanut siihen suostumuksensa. Vanhemman
          tai laillisen huoltajan on luettava sovelluksen käyttöehdot kanssasi.
          Jos olet lapsen vanhempi tai laillinen huoltaja ja annat hänen käyttää
          EkoVAV-sovellusta, nämä ehdot koskevat sinua, ja olet vastuussa
          lapsesi toiminnasta sovelluksessa.
        </p>
        <p>
          Hyväksymällä nämä käyttöehdot vakuutat toimineesi edellä mainitulla
          tavalla.
        </p>
        <h3>2.3. Ehtojen muuttaminen ja sopimuksen voimassaolo</h3>
        <p>
          VAV voi muuttaa sovelluksen käyttöehtoja ilman ennakkoilmoitusta.
          Voimassaolevat ehdot löydät aina{" "}
          <a
            href="https://vav.fi/kayttoehdot-2_2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            täältä
          </a>
          . Jos muutamme ehtoja olennaisesti, ilmoittamme sinulle muutoksista
          ennen niiden voimaantuloa. Jatkamalla sovelluksen käyttöä hyväksyt
          sovellukseen ja ehtoihin tekemämme muutokset. Jos et hyväksy
          muutettuja ehtoja, voit pyytää meiltä henkilötietojesi poistamista ja
          lopettaa sovelluksen käytön.
        </p>
        <p>
          Jos et enää halua käyttää EkoVAV-sovellusta, voit milloin tahansa
          irtisanoa sopimuksen pyytämällä meiltä henkilötietojesi poistamista ja
          lopettamalla sovelluksen käyttämisen.
        </p>
        <p>
          VAV:lla on oikeus ilman erillistä ilmoitusta irtisanoa oikeutesi
          käyttää sovellusta, jos VAV:lla on perusteltu syy epäillä, että käytät
          sovellusta tai sen sisältöä käyttöehtojen, lain tai muutoin hyvän
          tavan vastaisesti.
        </p>
        <p>
          Sisällöstä saattaa jäädä jälkiä tai kopioita sovellukseen senkin
          jälkeen, kun sinä olet tai VAV on poistanut sisällön sovelluksesta.
        </p>
        <p>
          Jos ilmenee, että jokin käyttöehtojen kohta on pätemätön, ehtojen
          kaikki muut kohdat pysyvät edelleen voimassa.
        </p>

        <h2>3. Sovelluksen käyttö</h2>
        <p>Sinä sitoudut noudattamaan näitä sääntöjä, kun käytät sovellusta:</p>
        <p>
          <ol>
            <li>
              Sinulla on oikeus käyttää sovellusta vain yksityisiin,
              ei-kaupallisiin tarkoituksiin.
            </li>
            <li>Noudatat soveltuvia lakeja, näitä ehtoja ja hyviä tapoja.</li>
            <li>
              Kunnioitat toisten oikeuksia, mukaan luettuna yksityisyyttä ja
              immateriaalioikeuksia.
            </li>
            <li>
              Et toimi haitallisesti itseäsi tai muita kohtaan etkä uhkaile
              tällaisella toiminnalla tai kannusta siihen.
            </li>
            <li>
              Et lähetä laitonta, loukkaavaa, herjaavaa, pornografista,
              häiritsevää, kunniaa loukkaavaa tai muuten sopimatonta sisältöä.
            </li>
            <li>
              Hankit kaikki tarvitsemasi suostumukset, luvat tai oikeudet
              sisällön lailliseen lähettämiseen.
            </li>
            <li>
              Et lähetä sisältöä, joka on roskapostia tai sisältää sovellukselle
              tai sen käyttäjien edulle tai omaisuudelle haitallisia tiedostoja
              tai tekniikoita.
            </li>
            <li>
              Et väärinkäytä tai vahingoita sovellusta tai häiritse sen
              toimintaa.
            </li>
          </ol>
        </p>

        <h2>4. Sisältö sovelluksessa</h2>
        <h3>4.1. Käyttäjän tuottama sisältö</h3>
        <p>
          Voit halutessasi suorittaa sovelluksessa henkilökohtaisia
          haastetehtäviä ja osallistua asuinkohteesi yhteisöhaasteisiin.
          Haastetehtäviä suorittamalla keräät itsellesi ja asuinkohteellesi
          pisteitä, ja näet sovelluksessa oman ja muiden käyttäjien
          pistesijoituksen sekä asuinkohteiden pistesijoitukset. Muut käyttäjät
          eivät näe, mitä henkilökohtaisia haastetehtäviä olet suorittanut, vaan
          ainoastaan keräämäsi pistemäärän pistesijoituksessa. Muut käyttäjät
          saavat tiedon yhteisöhaastetehtävästä, jonka olet suorittanut
          ensimmäisenä asuinkohteessasi.
        </p>
        <p>Voimme halutessamme tarkistaa ja poistaa sovelluksesta sisältöä.</p>
        <p>
          Jos annat meille palautetta, voimme oman harkintamme mukaan ryhtyä
          palautteesi mukaisiin toimiin.
        </p>

        <h3>4.2. VAV:n tuottama sisältö</h3>
        <p>
          Sovellus, sen sisältö ja ulkoasu ovat tekijänoikeuslakien ja kaikkien
          muiden soveltuvien lainsäädäntöjen suojaamia. Kaikki oikeudet, kuten
          omistusoikeus, tekijänoikeus ja muut immateriaalioikeudet sovellukseen
          sekä siihen sisältyvään tai liittyvään sisältöön ovat VAV:n tai
          lisenssinantajiemme omaisuutta. Pidätämme kaikki oikeudet, joita ei
          nimenomaisesti luovuteta ehdoissa.
        </p>

        <h3>4.3. Muu sisältö</h3>
        <p>
          Sovelluksessa voi olla linkkejä kolmansien osapuolien omistamille tai
          ylläpitämille sivustoille. Näitä sivustoja käytetään kyseisten
          sivustojen sääntöjen mukaisesti.
        </p>
        <p>
          VAV ei voi vaikuttaa kolmansien osapuolten sivustojen sisältöön eikä
          ole tarkistanut tai hyväksynyt linkillä sovellukseen yhdistettyjä
          kolmansien osapuolten sivustoja. VAV ei siten ole vastuussa niiden
          sisällöstä.
        </p>

        <h2>5. Saatavuus</h2>
        <p>
          EkoVAV-sovellus on saatavilla vain Suomessa ja se on käytettävissä
          vain suomen kielellä.
        </p>
        <p>
          Sovelluksen käyttäminen edellyttää verkkoyhteyttä. Sinä vastaat
          sovelluksen käytön edellyttämien mobiililaitteiden, ohjelmistojen ja
          viestintä- sekä tietoliikenneyhteyksien hankkimisesta, kustannuksista
          ja toimivuudesta.
        </p>
        <p>
          VAV voi muuttaa, parantaa ja korjata sovellusta oman harkintansa
          mukaan. Sovellus voi olla poissa käytöstä huoltokatkojen aikana tai
          muina aikoina.
        </p>
        <p>
          VAV voi myös päättää sovelluksen tai sen osan lopettamisesta oman
          harkintansa mukaan. Tällaisessa tapauksessa ilmoitamme asiasta
          etukäteen.
        </p>

        <h2>6. Vastuunrajoitus</h2>
        <p>
          VAV ei vastaa sovelluksen virheellisestä toiminnasta, käyttökatkoista,
          käytön estymisestä taikka sovelluksessa tai sen sisällössä olevista
          virheistä tai puutteellisuuksista. Emme vastaa myöskään kolmansista
          osapuolista johtuvista tiedonsiirtoon liittyvistä ongelmista tai
          virheistä, jotka johtuvat siitä, että sinä käytät sovellusta ohjeiden
          tai määräysten vastaisesti tai jotka ovat muuten sinun aiheuttamiasi.
          Emme vastaa sinulle tai millekään kolmannelle osapuolelle sovelluksen
          tai jonkin sen osan mahdollisesta lopettamisesta aiheutuvasta
          vahingosta.
        </p>
        <p>
          VAV ei myönnä sovelluksen saatavuuden, tarkkuuden, luotettavuuden,
          tietojen tai sisällön osalta minkäänlaisia takuita.
        </p>
        <p>
          VAV:n vastuu rajoittuu aina korkeintaan pakottavan lainsäädännön
          mukaiseen vastuuseen.
        </p>
        <p>
          Olet velvollinen korvaamaan VAV:lle välittömät vahingot, jotka
          aiheutuvat sovelluksen käyttämisestä lain tai näiden ehtojen
          vastaisesti.
        </p>

        <h2>7. Tietosuoja</h2>
        <p>
          Jos käytät Sovellusta, keräämme sinusta Sovelluksessa antamiasi
          tietoja Sovelluksen tarjoamiseksi ja kehittämiseksi sekä sovellus- ja
          käyttäjäviestintää varten. VAV on rekisterinpitäjänä vastuussa siitä,
          että henkilötietojasi käsitellään tietosuojalainsäädännön
          edellyttämällä tavalla. Käsittelemme henkilötietojasi ehtojen ja{" "}
          <a
            href="https://vav.fi/tietoa-meista/tietosuoja/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tämän tietosuojaselosteen
          </a>{" "}
          mukaisesti.
        </p>

        <h2>8. Erimielisyyksien ratkaiseminen</h2>
        <p>
          Näihin käyttöehtoihin sovelletaan Suomen lakia. Kuluttajakäyttäjänä
          voit saattaa käyttöehtoja koskevan erimielisyyden käsiteltäväksi myös
          kotipaikkasi toimivaltaisen käräjäoikeuden tai
          kuluttajariitalautakunnan (
          <a
            href="http://www.kuluttajariita.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.kuluttajariita.fi
          </a>
          ) ratkaistavaksi. Ennen asian viemistä kuluttajariitalautakunnan
          käsiteltäväksi sinun on oltava yhteydessä kuluttajaneuvontaan (
          <a
            href="http://www.kuluttajaneuvonta.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.kuluttajaneuvonta.fi
          </a>
          ).
        </p>

        <h2>9. Yhteystiedot</h2>
        <p>
          Jos sinulla on EkoVAV-sovellusta koskevia kysymyksiä, ota yhteyttä{" "}
          <a href="mailto:viestinta@vav.fi">viestinta@vav.fi</a>.
        </p>
      </div>
    </div>
  );
}

export default Terms;
