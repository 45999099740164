import axios from "axios";
import { apiUrl } from "../helpers/helpers";

export default class Api {
  constructor(data) {
    this.accessToken = data && data.accessToken;
    this.client = data && data.client;
    this.uid = data && data.uid;
    this.tokenType = data && data.tokenType;
  }

  /*getChallenges() {
    return this.get("/user/challenges.json");
  }*/

  getPersonalChallenges() {
    return this.get("/user/challenges/personal.json");
  }

  getCommunalChallenges() {
    return this.get("/user/challenges/communal.json");
  }

  /*getChallengesCompleted(num = 0) {
    return this.get(`/user/challenges/completed?season_offset=${num}`);
  }*/

  getPersonalChallengesCompleted(num = 0) {
    return this.get(`/user/completed_challenges/personal?season_offset=${num}`);
  }

  getCommunalChallengesCompleted(num = 0) {
    return this.get(`/user/completed_challenges/communal?season_offset=${num}`);
  }

  getDailyChallenge() {
    return this.get("/user/challenges/challenge_of_the_day.json");
  }

  getSeason(num = 0) {
    return this.get(`/season?season_offset=${num}`).then(data => {
      return data && data.data;
    });
  }

  getComplexes() {
    return this.get("/building_complexes.json");
  }

  getCurrentComplex() {
    return this.get("/user/building_complexes/current").then(data => {
      return data && data.data;
    });
  }

  changeComplex(payload) {
    return this.request("PUT", "/user/building_complexes", payload).then(res =>
      res.json()
    );
  }

  getUserStatistics(year) {
    const path = "/user/statistics/personal" + (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getComplexStatistics(year) {
    const path = "/user/statistics/communal" + (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getLeadersAllUsers(year) {
    const path =
      "/user/leaderboards/personal/all_users" + (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getLeadersOwnCommunity(year) {
    const path =
      "/user/leaderboards/personal/own_community" +
      (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getLeadersAllUsersTop10(year) {
    const path =
      "/user/leaderboards/personal/all_users_top_10" +
      (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getUserCommunal(year) {
    const path = "/user/leaderboards/communal" + (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  getUserCommunalTop10(year) {
    const path =
      "/user/leaderboards/communal_top_10" + (year ? `?year=${year}` : "");
    return this.get(path).then(data => {
      return data && data.data;
    });
  }

  loginUser(email, password) {
    return this.requestAxios("POST", "/auth/sign_in", { email, password }).then(
      data => {
        return { data: data.data, headers: data.headers };
      }
    );
  }

  registerUser(credentials) {
    return this.requestAuth("POST", "/auth", credentials).then(res =>
      res.json().then(data => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  validateToken() {
    return this.get("/auth/validate_token").then(data => {
      return { data: data.data };
    });
  }

  logoutUser() {
    return this.request("DELETE", "/auth/sign_out").then(res => {
      return res.json();
    });
  }

  getPasswordResetToken(params) {
    return this.requestAuth("POST", `/auth/password`, params).then(res => {
      return res.json();
    });
  }

  resetPassword(headers, params) {
    return this.requestAuth(
      "PUT",
      `/auth/password`,
      params,
      headers
    ).then(res => res.json());
  }

  sendConfirmation(params) {
    return this.requestAuth("POST", `/auth/confirmation`, params).then(res =>
      res.json()
    );
  }

  acceptChallenge(id) {
    return this.request("POST", `/user/challenges/${id}/accept`).then(res => {
      return res.json();
    });
  }

  cancelChallenge(id) {
    return this.request("DELETE", `/user/challenges/${id}/abandon`).then(
      res => {
        return res.json();
      }
    );
  }

  submitChallengeForm(payload) {
    return this.request("POST", `/user/dynamic_form_submissions`, payload).then(
      res => {
        return res.json();
      }
    );
  }

  completeChallenge(id, payload) {
    return this.request(
      "POST",
      `/user/challenges/${id}/complete`,
      payload
    ).then(res => {
      return res.json();
    });
  }

  getFeed() {
    return this.get("/user/feed_items.json");
  }

  getFeedContent(id) {
    const url = `/user/feed_items/${id}/content`;
    return new Promise((resolve, reject) => {
      fetch(apiUrl(url), {
        headers: {
          "Content-Type": "html/text",
          "access-token": this.accessToken,
          "token-type": this.tokenType,
          client: this.client,
          uid: this.uid,
        },
      }).then(
        res => {
          if (res.ok) {
            res.text().then(function(text) {
              resolve({ data: text, headers: res.headers, response: res });
            });
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        },
        err => {
          console.warn("Fetch error", err);
        }
      );
    });
  }

  getChallengeContent(id) {
    const url = `/user/challenges/${id}/content`;
    return new Promise((resolve, reject) => {
      fetch(apiUrl(url), {
        headers: {
          "Content-Type": "html/text",
          "access-token": this.accessToken,
          "token-type": this.tokenType,
          client: this.client,
          uid: this.uid,
        },
      }).then(
        res => {
          if (res.ok) {
            res.text().then(function(text) {
              resolve({ data: text, headers: res.headers, response: res });
            });
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        },
        err => {
          console.warn("Fetch error", err);
        }
      );
    });
  }

  get(url) {
    return new Promise((resolve, reject) => {
      fetch(apiUrl(url), {
        headers: {
          "Content-Type": "application/json",
          "access-token": this.accessToken,
          "token-type": this.tokenType,
          client: this.client,
          uid: this.uid,
        },
      }).then(
        res => {
          if (res.ok) {
            res
              .json()
              .then(data =>
                resolve({ data, headers: res.headers, response: res })
              );
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        },
        err => {
          console.warn("Fetch error", err);
        }
      );
    });
  }

  request(method, url, body) {
    return new Promise((resolve, reject) => {
      let params = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "access-token": this.accessToken,
          "token-type": this.tokenType,
          client: this.client,
          uid: this.uid,
        },
      };
      if (body) {
        params.body = JSON.stringify(body);
      }
      console.log("apiUrl", apiUrl(url), params);
      fetch(apiUrl(url), params).then(
        res => {
          if (res.ok) {
            resolve(res);
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        },
        err => {
          console.warn("Fetch error", err);
        }
      );
    });
  }

  requestAuth(method, url, body, headers) {
    return new Promise((resolve, reject) => {
      let params = {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (body) {
        params.body = JSON.stringify(body);
      }
      if (headers) {
        params.headers = {
          "Content-Type": "application/json",
          "access-token": headers["access-token"],
          "token-type": headers["token-type"] || "Bearer",
          client: headers.client,
          uid: headers.uid && decodeURIComponent(headers.uid),
        };
      }
      fetch(apiUrl(url), params).then(
        res => {
          if (res.ok) {
            resolve(res);
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else if (res.status === 422) {
              res.json().then(data => reject(data));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        },
        err => {
          console.warn("Fetch error", err);
        }
      );
    });
  }

  requestAxios(method, url, body, headers) {
    return new Promise((resolve, reject) => {
      const config = {
        method: method,
        url: apiUrl(url),
      };
      if (body) {
        config.data = body;
      } else if (headers) {
        config.headers = {
          "Content-Type": "application/json",
          "access-token": this.accessToken,
          "token-type": this.tokenType,
          client: this.client,
          uid: this.uid,
        };
      }

      axios(config)
        .then(res => {
          if (res && res.status === 200) {
            resolve(res);
          } else {
            if (res.status === 401) {
              reject(new Error("No access"));
            } else {
              reject(new Error("API returned incorrect response"));
            }
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            reject(new Error(err.response.statusText));
          } else {
            reject(new Error(err.message));
          }
        });
    });
  }
}
