import React from "react";
import styles from "./Ranking.module.scss";
import classNames from "classnames";

import Tag from "../Tag/Tag";
import SectionHeading from "../SectionHeading/SectionHeading";

import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import Spinner from "../Spinner/Spinner";

const Ranking = ({ title, type, ranking, loading }) => {
  return (
    <div className={styles.ranking}>
      <div className={styles.title}>
        <SectionHeading>{title}</SectionHeading>
      </div>
      {loading ? (
        <Spinner>Ladataan</Spinner>
      ) : (
        <div className={styles.ranks}>
          {ranking && ranking.length ? (
            ranking.map((rank, index) => {
              const rankClassNames = classNames(styles.rank, {
                [styles.self]: rank.self,
              });

              return (
                <div key={index} className={rankClassNames}>
                  <div className={styles.rankNumber}>
                    <Tag
                      value={rank.rank}
                      darkRed={rank.rank === 1 ? true : false}
                      darkBlue={rank.rank === 2 ? true : false}
                      blue={rank.rank === 3 ? true : false}
                      grey={rank.rank > 3 ? true : false}
                      center
                    />
                  </div>
                  <span className={styles.name}>{rank.scorer_name}</span>
                  <div className={styles.points}>
                    <Tag
                      icon={<StarOutlineIcon />}
                      value={rank.total_points}
                      blue={type === "own"}
                      red={type === "communal"}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p>-</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Ranking;
