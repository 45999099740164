import React, { useState, useEffect } from "react";
import styles from "./PageContainer.module.scss";
import classNames from "classnames";
import Tabs from "../Tabs/Tabs";
import AnimatedSwitch from "../AnimatedSwitch/AnimatedSwitch";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const PageContainer = ({ children, tabs }) => {
  if (tabs) {
    return <TabContainer tabs={tabs}>{children}</TabContainer>;
  } else {
    return (
      <div className={`${styles.pageContainer} ${styles.noTabs}`}>
        {children}
      </div>
    );
  }
};

const TabContainer = ({ children, tabs }) => {
  const [tabBarSeparator, setTabBarSeparator] = useState(false);
  const [animation, setAnimation] = useState("left-to-right-animation");
  const animationDuration = 400;

  const tabBarClassNames = classNames(styles.tabBar, {
    [styles.separator]: tabBarSeparator
  });

  const handleScroll = e => {
    if (!tabBarSeparator && e.target.scrollTop > 10) {
      setTabBarSeparator(true);
    } else if (tabBarSeparator && e.target.scrollTop <= 10) {
      setTabBarSeparator(false);
    }
  };

  useEffect(() => {
    let animationTimer;
    let scrollTimer;

    if (tabs.active === 1 && animation === "left-to-right-animation") {
      animationTimer = window.setTimeout(() => {
        setAnimation("right-to-left-animation");
      }, animationDuration);

      scrollTimer = window.setTimeout(() => {
        document.querySelector("#scrollContainer").scrollTo(0, 0);
      }, animationDuration / 2);
    }

    if (tabs.active === 0 && animation === "right-to-left-animation") {
      animationTimer = window.setTimeout(() => {
        setAnimation("left-to-right-animation");
      }, animationDuration);

      scrollTimer = window.setTimeout(() => {
        document.querySelector("#scrollContainer").scrollTo(0, 0);
      }, animationDuration / 2);
    }

    document
      .querySelector("#scrollContainer")
      .addEventListener("scroll", handleScroll);

    return function cleanup() {
      document
        .querySelector("#scrollContainer")
        .removeEventListener("scroll", handleScroll);
      window.clearTimeout(animationTimer);
      window.clearTimeout(scrollTimer);
    };
  });

  return (
    <div className={`${styles.pageContainer} ${styles.withTabs}`}>
      <div className={tabBarClassNames}>
        <Tabs tabInfo={tabs} />
      </div>
      <div id="scrollContainer" className={styles.tabPage}>
        <AnimatedSwitch
          keySlice={[tabs.page.length, tabs.page.length + 5]}
          animation={animation}
          timeout={animationDuration}
        >
          {children}
        </AnimatedSwitch>
      </div>
    </div>
  );
};

export const TabPage = ({ children }) => (
  <div className={styles.tabPageInner}>{children}</div>
);

export const TransitionContainer = ({
  children,
  keyValue,
  timeout,
  animation
}) => (
  <TransitionGroup>
    <CSSTransition key={keyValue} timeout={timeout} classNames={animation}>
      <div className={styles.transitionContainer}>
        <div className={styles.transitionContainerInner}>{children}</div>
      </div>
    </CSSTransition>
  </TransitionGroup>
);

export default PageContainer;
