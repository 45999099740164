import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";

import SecurityContext from "../Context/SecurityContext";
import PageContainer from "../PageContainer/PageContainer";
import Container from "../Container/Container";
import Spinner from "../Spinner/Spinner";
import { Button, InputError } from "../Inputs/Inputs";
import { complexSelectStyles } from "../../helpers/stylesHelper";
import styles from "./ChangeComplex.module.scss";

export default function ChangeComplex() {
  const { api } = useContext(SecurityContext);

  const [complexes, setComplexes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [complexChanged, setComplexChanged] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setLoading(true);
      api
        .getComplexes()
        .then(response => {
          if (response && response.data) {
            const list = response.data.map(item => {
              return {
                label: item.identifier + " " + item.name,
                addresses: item.addresses,
                value: item.id,
              };
            });
            setComplexes(list);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <PageContainer>
      <Container>
        <div>
          <Link to="/settings">
            <ArrowLeftIcon />
          </Link>
        </div>
        {complexChanged ? (
          <div className={styles.text}>
            Asuinkohde vaihdettiin onnistuneesti. Uusi asuinkohteesi on{" "}
            {complexChanged}.
          </div>
        ) : (
          <ChangeComplexForm
            api={api}
            complexes={complexes}
            loading={loading}
            setComplexChanged={setComplexChanged}
          />
        )}
      </Container>
    </PageContainer>
  );
}

function ChangeComplexForm({ api, complexes, loading, setComplexChanged }) {
  const [error, setError] = useState("");

  const ComplexSchema = Yup.object().shape({
    complex: Yup.string().required("Pakollinen tieto"),
  });

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    setError("");

    const payload = {
      building_complex: { id: values.complex },
    };

    api
      .changeComplex(payload)
      .then(data => {
        setSubmitting(false);
        if (data && data.identifier) {
          setComplexChanged(`${data.identifier} ${data.name}`);
        } else {
          setError("Ei voitu päivittää");
        }
      })
      .catch(err => {
        setSubmitting(false);
        if (err.message) {
          setError(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        complex: "",
      }}
      validationSchema={ComplexSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting)
      }
    >
      {props => {
        const { values, errors, isValid, setFieldValue, isSubmitting } = props;

        return (
          <Form>
            <div style={{ marginTop: "10px" }}>
              <span className={styles.complexLabel}>Asuinkohde</span>
              <Select
                name="complex"
                options={complexes || []}
                components={{ Option }}
                filterOption={customFilter}
                styles={complexSelectStyles}
                placeholder="Hae nimellä tai osoitteella"
                onChange={selected => {
                  setFieldValue("complex", selected.value);
                }}
              />
              {loading ? <Spinner>Ladataan</Spinner> : null}
              {errors.complex ? (
                <InputError>{errors.complex}</InputError>
              ) : null}
              {error && <InputError>{error}</InputError>}
            </div>

            <div className={styles.submit}>
              <Button
                type="submit"
                disabled={isSubmitting || !isValid || !values.complex}
              >
                Vaihda asuinkohdetta
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const Option = props => {
  const addresses = props.data.addresses && props.data.addresses.join(", ");
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <div style={{ fontSize: 12 }}>{addresses}</div>
    </components.Option>
  );
};

const customFilter = (option, searchText) => {
  if (!searchText) return true;
  const label = option.data.label && option.data.label.toLowerCase();
  const addresses = option.data.addresses && option.data.addresses.join(", ");
  if (
    label.includes(searchText.toLowerCase()) ||
    addresses.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};
