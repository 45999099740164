import React, { useState, useEffect, useContext } from "react";
import { Collapse } from "react-collapse";
import ReactMarkdown from "react-markdown/with-html";
import classNames from "classnames";
import styles from "./Challenge.module.scss";

import ChallengeInfo from "../ChallengeInfo/ChallengeInfo";
import ChallengeForm from "../ChallengeForm/ChallengeForm";
import Spinner from "../Spinner/Spinner";
import Tag from "../Tag/Tag";

import { Flag, Blink, HandSmall } from "../Symbols/Symbols";

import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import SecurityContext from "../Context/SecurityContext";

const Challenge = ({
  id,
  type,
  title,
  icon,
  personalPoints,
  communalPoints,
  endDate,
  completionCount,
  form,
  description,
  completed,
  initAccepted,
}) => {
  const { api } = useContext(SecurityContext);

  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(initAccepted);
  const [completionStage, setCompletionStage] = useState(false);
  const [challengeCompleted, setCompleted] = useState(false);
  const [hiding, setHiding] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [points, setPoints] = useState(personalPoints);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let challengeIcon = <Flag />;
  if (icon === "blink") challengeIcon = <Blink />;
  if (icon === "hand") challengeIcon = <HandSmall />;

  let challengeInfoTags = [];
  if (points) {
    challengeInfoTags.push(
      <Tag icon={<StarOutlineIcon />} value={points} blue />
    );
  }
  if (communalPoints) {
    challengeInfoTags.push(
      <Tag icon={<StarOutlineIcon />} value={communalPoints} red />
    );
  }

  let challengeInfoTexts = [];
  if (endDate) {
    const expires = endDate
      .split("-")
      .reverse()
      .join(".");
    challengeInfoTexts.push("Päättyy " + expires);
  }
  if (completionCount) challengeInfoTexts.push(completionCount + " suoritusta");

  const challengeClassNames = classNames(styles.challenge, {
    [styles.open]: open,
    [styles.accepted]: accepted,
    [styles.hiding]: hiding,
    [styles.hidden]: hidden,
    [styles.standard]: type === "standard" || !type,
    [styles.winter]: type === "winter",
    [styles.spring]: type === "spring",
    [styles.summer]: type === "summer",
    [styles.autumn]: type === "autumn",
    [styles.daily]: type === "daily",
  });

  useEffect(() => {
    let hidingTimer;
    let hiddenTimer;

    if (challengeCompleted) {
      hidingTimer = window.setTimeout(() => {
        setHiding(true);
      }, 1800);
    }

    if (hiding) {
      hiddenTimer = window.setTimeout(() => {
        setHidden(true);
      }, 500);
    }

    return function cleanup() {
      window.clearTimeout(hidingTimer);
      window.clearTimeout(hiddenTimer);
    };
  });

  useEffect(() => {
    let ignore = false;
    if (!ignore && open && !content && api) {
      setLoading(true);
      api
        .getChallengeContent(id)
        .then(response => {
          if (response && response.data) {
            setContent({ data: response.data, error: null });
          } else if (description) {
            setContent({ data: description, error: null });
          }
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          if (description) {
            setContent({ data: description, error: null });
          } else {
            setContent({ data: null, error: "Tietojen haku epäonnistui" });
          }
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api, open, id, content, description]);

  function openItem() {
    if (!open && !content && api) {
      setLoading(true);
      api
        .getChallengeContent(id)
        .then(response => {
          if (response && response.data) {
            setContent({ data: response.data, error: null });
          } else if (description) {
            setContent({ data: description, error: null });
          }
          setLoading(false);
          setOpen(!open);
        })
        .catch(err => {
          console.log(err);
          if (description) {
            setContent({ data: description, error: null });
          } else {
            setContent({ data: null, error: "Tietojen haku epäonnistui" });
          }
          setLoading(false);
          setOpen(!open);
        });
    } else {
      setOpen(!open);
    }
  }

  function actionChallenge() {
    if (completionStage) {
      setCompletionStage(false);
    } else if (api) {
      if (accepted) {
        api.cancelChallenge(id).then(data => {
          if (data && data.id) {
            setAccepted(data.accepted);
          }
        });
      } else {
        api.acceptChallenge(id).then(data => {
          if (data && data.id) {
            setAccepted(data.accepted);
          }
        });
      }
    }
  }

  function answer(values) {
    if (
      form &&
      form.form_fields &&
      form.form_fields.length &&
      !completionStage
    ) {
      setCompletionStage(true);
    } else if (values) {
      submitForm(values);
    } else {
      complete();
    }
  }

  function submitForm(values) {
    const fieldValues = [];
    Object.keys(values).forEach(fieldId => {
      if (Array.isArray(values[fieldId])) {
        values[fieldId].forEach(val => {
          fieldValues.push({
            form_field_id: fieldId,
            value: val,
          });
        });
      } else {
        fieldValues.push({
          form_field_id: fieldId,
          value: values[fieldId],
        });
      }
    });
    const payload = {
      dynamic_form_submission: {
        dynamic_form_id: form.id,
        challenge_id: id,
        form_field_values_attributes: fieldValues,
      },
    };

    if (api) {
      api
        .submitChallengeForm(payload)
        .then(data => {
          if (data && data.id) {
            const submissionId = { dynamic_form_submission_id: data.id };
            complete(submissionId);
          } else {
            setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
          }
        })
        .catch(err => {
          console.log(err);
          setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
        });
    } else {
      setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
    }
  }

  function complete(payload) {
    if (api) {
      api
        .completeChallenge(id, payload)
        .then(data => {
          if (data && data.id) {
            // const communal = findRewards(data.rewards, "communal");
            const personal = findRewards(data.rewards, "personal");
            setPoints(personal);
            setCompleted(true);
          } else {
            setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
          }
        })
        .catch(err => {
          console.log(err);
          setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
        });
    } else {
      setError("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
    }
  }

  return (
    <div className={challengeClassNames}>
      <div className={styles.container}>
        <div className={styles.icon}>{challengeIcon}</div>
        <div className={styles.content}>
          <div className={styles.clickable} onClick={() => openItem()}>
            <div className={styles.top}>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.openIndicatorContainer}>
                {loading && (
                  <div className={styles.spinnerContainer}>
                    <Spinner className={styles.spinner} />
                  </div>
                )}
                <div className={styles.openIndicator}>
                  <ChevronDownIcon />
                </div>
              </div>
            </div>
            <div className={styles.middle}>
              <ChallengeInfo
                tags={challengeInfoTags}
                texts={challengeInfoTexts}
              />
            </div>
          </div>
          <Collapse isOpened={open}>
            {content && (
              <div className={styles.description}>
                {content.data ? (
                  <ReactMarkdown source={content.data} escapeHtml={false} />
                ) : (
                  content.error && content.error
                )}
              </div>
            )}
            {completionStage && (
              <ChallengeForm
                formFields={form.form_fields}
                completed={completed}
                accepted={accepted}
                actionChallenge={actionChallenge}
                answer={answer}
                error={error}
              />
            )}
          </Collapse>
          {!completionStage && (
            <Collapse isOpened={!completed && open}>
              <div className={styles.buttons}>
                <div className={styles.button}>
                  <button
                    className={styles.buttonOutline}
                    onClick={() => actionChallenge()}
                  >
                    {accepted ? "Peru haaste" : "Hyväksy haaste"}
                  </button>
                </div>
                {accepted && (
                  <div
                    className={`${styles.button} ${styles.animateButtonOuter}`}
                  >
                    <button
                      className={`${styles.buttonSolid} ${styles.animateButtonInner}`}
                      onClick={() => answer()}
                    >
                      {form && form.form_fields && form.form_fields.length
                        ? "Vastaa ja suorita"
                        : "Suorita haaste"}
                    </button>
                  </div>
                )}
              </div>
            </Collapse>
          )}
          {challengeCompleted && (
            <div className={styles.completed}>
              <div className={styles.completedInner}>
                <StarOutlineIcon />
                <div className={styles.plusPoints}>
                  +{points || communalPoints}
                </div>
              </div>
            </div>
          )}
          {hiding && <div className={styles.hidingOverlay}></div>}
        </div>
      </div>
    </div>
  );
};

export default Challenge;

function findRewards(list, type) {
  if (list && list.length) {
    const found = list.find(i => i.type === type);
    return found && found.value;
  }
}
