import React from "react";
import styles from "./SectionHeading.module.scss";
import classNames from "classnames";

const SectionHeading = ({ children, white, tall }) => {
  const headingClassNames = classNames(styles.sectionHeading, {
    [styles.white]: white,
    [styles.tall]: tall
  });

  return <h1 className={headingClassNames}>{children}</h1>;
};

export default SectionHeading;
