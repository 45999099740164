import React from "react";
import styles from "./Header.module.scss";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import headerLogo from "../../assets/header-logo.png";

import SectionHeading from "../SectionHeading/SectionHeading";

const Header = ({ title, color, emblem }) => {
  const headerClassNames = classNames(styles.header, {
    [styles.green]: color === "green" || !color,
    [styles.blue]: color === "blue",
    [styles.red]: color === "red",
    [styles.white]: color === "white",
  });

  return (
    <header className={headerClassNames}>
      <div className={styles.container}>
        <TransitionGroup>
          <CSSTransition
            key={title}
            timeout={300}
            classNames="header-animation"
          >
            <div className={styles.heading}>
              {emblem && <img src={headerLogo} alt="EkoVAV" />}
              {!emblem && (
                <>
                  {color === "green" || color === "white" ? (
                    <SectionHeading>{title}</SectionHeading>
                  ) : (
                    <SectionHeading white>{title}</SectionHeading>
                  )}
                </>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </header>
  );
};

export default Header;
