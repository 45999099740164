import React, { useContext, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";

import SecurityContext from "../Context/SecurityContext";
import { Button, InputError, TextInput } from "../Inputs/Inputs";
import { redirectUrl } from "../../helpers/helpers";
import styles from "./ForgotPassword.module.scss";

export default function ForgotPassword({ setView, resend }) {
  const { api } = useContext(SecurityContext);

  const [sent, setSent] = useState(null);
  const [error, setError] = useState("");

  const ResetPswdSchema = Yup.object().shape({
    email: Yup.string()
      .email("Sähköpostiosoite on virheellinen")
      .required("Pakollinen tieto"),
  });

  const handleSubmit = (values, setSubmitting) => {
    if (resend) {
      resendConfirmation(values, setSubmitting);
    } else {
      getPasswordResetToken(values, setSubmitting);
    }
  };

  async function getPasswordResetToken(values, setSubmitting) {
    const redirect_url = await redirectUrl("/reset-password");
    setSubmitting(true);
    setError("");
    api
      .getPasswordResetToken({
        email: values.email,
        redirect_url: redirect_url,
      })
      .then(data => {
        setSubmitting(false);
        if (data && data.success) {
          setSent(true);
        } else if (data) {
          setError(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setError(err.message);
      });
  };

  async function resendConfirmation(values, setSubmitting) {
    const redirect_url = await redirectUrl("/note");
    setSubmitting(true);
    setError("");
    api
      .sendConfirmation({
        email: values.email,
        redirect_url: redirect_url,
      })
      .then(data => {
        setSubmitting(false);
        if (data && data.success) {
          setSent(true);
        } else if (data) {
          setError(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setError(err.message);
      });
  };

  return (
    <div>
      <div className={styles.back} onClick={() => setView("login")}>
        <ArrowLeftIcon />
      </div>
      <div>
        {!sent ? (
          <Formik
            initialValues={{ email: "" }}
            validationSchema={ResetPswdSchema}
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
          >
            {props => {
              const { isSubmitting, isValid } = props;
              return (
                <Form>
                  <div className="margin-1 margin-bottom">
                    <TextInput
                      formik
                      required
                      type="email"
                      name="email"
                      label="Sähköposti"
                    />
                    <ErrorMessage name="email" component={InputError} />
                    <InputError>{error}</InputError>
                  </div>

                  <div className={styles.submit}>
                    <Button type="submit" disabled={isSubmitting || !isValid}>
                      {!resend
                        ? "Lähetä salasanan palautusohjeet"
                        : "Lähetä vahvistus uudelleen"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div className={styles.text}>Sähköpostin lähetys onnistui.</div>
        )}
      </div>
    </div>
  );
}
