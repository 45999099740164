import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import PageContainer from "../components/PageContainer/PageContainer";
import Container from "../components/Container/Container";
import { Button } from "../components/Inputs/Inputs";
import SecurityContext from "../components/Context/SecurityContext";

const Settings = ({ resetToken }) => {
  const { api } = useContext(SecurityContext);
  let history = useHistory();

  function editPassword() {
    history.push("/settings/change-password");
  }

  function editComplex() {
    history.push("/settings/change-complex");
  }

  function logout() {
    api
      .logoutUser()
      .then(data => {
        console.log(data && data.success && "Signed out");
        resetToken();
      })
      .catch(err => {
        console.log(err);
        resetToken();
      });
  }

  return (
    <PageContainer>
      <Container>
        <Button onClick={() => logout()}>Kirjaudu ulos</Button>
      </Container>
      <Container>
        <Button grey onClick={() => editPassword()}>
          Vaihda salasana
        </Button>
      </Container>
      <Container>
        <Button grey onClick={() => editComplex()}>
          Vaihda asuinkohdetta
        </Button>
      </Container>
    </PageContainer>
  );
};

export default Settings;
