import React from "react";
import { Link, useLocation } from "react-router-dom";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./Note.module.scss";
import loginBackground from "../../assets/login-background.png";
import loginLogo from "../../assets/login-logo.png";
import { queryParameters } from "../../helpers/helpers";

export default function Note() {
  let location = useLocation();
  let title = location.state && location.state.title;
  let msg = location.state && location.state.msg;

  const searchParams = location.search && queryParameters(location.search);
  if (searchParams && searchParams.account_confirmation_success) {
    title = "";
    msg = "Sähköpostisi on varmistettu! ";
  }

  return (
    <div className={styles.screenContainer}>
      <img className={styles.background} alt="EkoVAV" src={loginBackground} />
      <div className={styles.formContainer}>
        <div className={styles.logo}>
          <img src={loginLogo} alt="EkoVAV" />
        </div>
        <div className={styles.text}>
          {msg ? (
            <>
              {title && (
                <>
                  <SectionHeading>{title}</SectionHeading>
                  <br />
                </>
              )}
              <div>
                <span>{msg} </span>
                <Link to="/">Kirjaudu sisään</Link>
              </div>
            </>
          ) : (
            <Link to="/" className="hyperlink">
              Siirry sovellukseen
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
