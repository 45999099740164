import React from "react";
import { Collapse } from "react-collapse";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "../Challenge/Challenge.module.scss";
import {
  InputError,
  Checkbox,
  Radio,
  TextArea,
  TextInput,
} from "../Inputs/Inputs";

export default function ChallengeForm(props) {
  const {
    formFields,
    completed,
    accepted,
    actionChallenge,
    answer,
    error,
  } = props;

  const FormSchema = Yup.object().shape(getfieldsSchema(formFields));

  if (!formFields || !formFields.length) return null;

  return (
    <Formik initialValues={getFields(formFields)} validationSchema={FormSchema}>
      {({ values, errors, isSubmitting }) => {
        const valuesValid = checkValuesValid(values, errors, formFields);
        return (
          <Form>
            {formFields.map(field => {
              return <ChallengeFormField key={field.id} field={field} />;
            })}
            {error && <p className={styles.errMsg}>{error}</p>}
            <Collapse isOpened={!completed && accepted}>
              <div className={styles.buttons}>
                <div className={styles.button}>
                  <button
                    className={styles.buttonOutline}
                    onClick={() => actionChallenge()}
                  >
                    Takaisin
                  </button>
                </div>
                {accepted && (
                  <div
                    className={`${styles.button} ${styles.animateButtonOuter}`}
                  >
                    <button
                      className={`${styles.buttonSolid} ${styles.animateButtonInner}`}
                      onClick={() => answer(values)}
                      disabled={isSubmitting || !valuesValid}
                    >
                      Suorita haaste
                    </button>
                  </div>
                )}
              </div>
            </Collapse>
          </Form>
        );
      }}
    </Formik>
  );
}

function ChallengeFormField({ field }) {
  const { id, input_type, label, placeholder, required, options } = field;

  return (
    <div className={styles.completionStage}>
      {id && (
        <>
          {input_type === "textarea" && (
            <>
              <div className={styles.completionQuestion}>{label}</div>
              <TextArea
                formik
                name={id}
                placeholder={placeholder}
                required={required}
              />
              <ErrorMessage name={id} component={InputError} />
            </>
          )}
          {input_type === "text" && (
            <>
              <div className={styles.completionQuestion}>{label}</div>
              <TextInput
                formik
                name={id}
                placeholder={placeholder}
                required={required}
              />
              <ErrorMessage name={id} component={InputError} />
            </>
          )}
          {input_type === "radio" && options && options.length && (
            <>
              <div className={styles.completionQuestion}>{label}</div>
              <div className={styles.inputContainer}>
                {options.map((option, index) => {
                  return (
                    <Radio
                      formik
                      key={id + "-radio-" + index}
                      name={id}
                      id={option.value}
                      label={option.label}
                      value={option.label}
                    />
                  );
                })}
              </div>
              <ErrorMessage name={id} component={InputError} />
            </>
          )}
          {input_type === "checkbox" && options && options.length && (
            <>
              <div className={styles.completionQuestion}>{label}</div>
              <div className={styles.inputContainer}>
                {options.map((option, index) => {
                  return (
                    <Checkbox
                      formik
                      key={id + "-checkbox-" + index}
                      name={id}
                      id={option.value}
                      label={option.label}
                      value={option.label}
                    />
                  );
                })}
              </div>
              <ErrorMessage name={id} component={InputError} />
            </>
          )}
          {/*{input_type === "integer" && (
            <>
              <div className={styles.completionQuestion}>
                {`${label} (${placeholder})`}
              </div>
              <NumberInput
                formik
                name={id}
                min={min}
                max={max}
                required={required}
              />
              <ErrorMessage name={id} component={InputError} />
            </>
          )}
          {input_type === "date" && (
            <>
              <div className={styles.completionQuestion}>{label}</div>
              <DateInput
                formik
                name={id}
                label={placeholder}
                required={required}
              />
              <ErrorMessage name={id} component={InputError} />
            </>
          )}*/}
        </>
      )}
    </div>
  );
}

function getFields(formFields) {
  const fieldsNames = formFields.map(field => field.id);
  const fields = {};
  fieldsNames.forEach(field => {
    fields[field] = "";
  });
  return fields;
}

function getfieldsSchema(formFields) {
  const fieldsSchema = {};
  formFields.forEach(field => {
    if (["text", "textarea"].includes(field.input_type)) {
      fieldsSchema[field.id] = field.required
        ? Yup.string().required("Pakollinen tieto")
        : Yup.string();
    } else if (field.input_type === "radio") {
      fieldsSchema[field.id] = field.required
        ? Yup.string().required("Pakollinen tieto")
        : Yup.string();
    } else if (field.input_type === "checkbox") {
      fieldsSchema[field.id] = field.required
        ? Yup.array()
            .compact()
            .required("Pakollinen tieto")
        : Yup.array().compact();
    }
    /*else if (field.input_type === "integer") {
      fieldsSchema[field.id] = field.required
        ? Yup.number()
            .min(field.min, `Oltava vähintään ${field.min}`)
            .max(field.max, `Voi olla korkeintaan ${field.max}`)
            .integer("Täytyy olla kokonaisluku")
            .required("Pakollinen tieto")
        : Yup.number()
            .min(field.min, `Oltava vähintään ${field.min}`)
            .max(field.max, `Voi olla korkeintaan ${field.max}`)
            .integer("Täytyy olla kokonaisluku");
    } else if (field.input_type === "date") {
      fieldsSchema[field.id] = field.required
        ? Yup.date().required("Pakollinen tieto")
        : Yup.date();
    }*/
  });
  return fieldsSchema;
}

function checkValuesValid(values, errors, formFields) {
  let valid = true;
  formFields.forEach(field => {
    if (!checkField(field, values, errors)) {
      valid = false;
    }
  });
  return valid;
}

function checkField(field, values, errors) {
  if (field.required) {
    return values[field.id] && !errors[field.id];
  } else {
    return !errors[field.id];
  }
}
