import React, { useState, useEffect, useContext } from "react";
import { TabPage, TransitionContainer } from "../PageContainer/PageContainer";
import ChallengeContainer from "../ChallengeContainer/ChallengeContainer";
import SecurityContext from "../Context/SecurityContext";
import HistorySelector from "../HistorySelector/HistorySelector";
import { getExtendedList, getSeasonTitles, getYear } from "./ChallengesHelper";

export default function CompletedChallenges(props) {
  const { challengesHistory, setChallengesHistory } = props;
  const { api } = useContext(SecurityContext);

  const [error, setError] = useState(null);
  const [season, setSeason] = useState({});
  const [communalChallenges, setCommunalChallenges] = useState({});
  const [ownChallenges, setOwnChallenges] = useState({});
  const [communalLoading, setCommunalLoading] = useState(false);
  const [ownLoading, setOwnLoading] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      if (challengesHistory > -5 && challengesHistory < 1) {
        setCommunalLoading(true);
        api
          .getCommunalChallengesCompleted(challengesHistory)
          .then(response => {
            if (response) {
              const communal = getExtendedList(response.data, "communal");
              setCommunalChallenges({ data: communal, error: null });
            }
            setCommunalLoading(false);
          })
          .catch(err => {
            setCommunalChallenges({
              data: null,
              error: "Tietojen haku epäonnistui",
            });
            setError(err);
            setCommunalLoading(false);
          });
      }
    }
    return () => (ignore = true);
  }, [api, challengesHistory]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      if (challengesHistory > -5 && challengesHistory < 1) {
        setOwnLoading(true);
        api
          .getPersonalChallengesCompleted(challengesHistory)
          .then(response => {
            if (response) {
              const own = getExtendedList(response.data, "own");
              setOwnChallenges({ data: own, error: null });
            }
            setOwnLoading(false);
          })
          .catch(err => {
            setOwnChallenges({
              data: null,
              error: "Tietojen haku epäonnistui",
            });
            setError(err);
            setOwnLoading(false);
          });
      }
    }
    return () => (ignore = true);
  }, [api, challengesHistory]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      if (challengesHistory > -5 && challengesHistory < 1) {
        api
          .getSeason(challengesHistory)
          .then(data => {
            const result = data.start_date && getSeasonTitles(data.start_date);
            if (result) {
              const year = getYear(data.start_date);
              setSeason({
                name: result.name,
                period: result.period + " " + year,
              });
            }
          })
          .catch(err => {
            setSeason({});
            setError(err);
          });
      }
    }
    return () => (ignore = true);
  }, [api, challengesHistory]);

  return (
    <TabPage>
      <HistorySelector
        history={challengesHistory}
        setHistory={setChallengesHistory}
        season={season}
        error={error}
        context="Suoritetut haasteet ajalta"
      />
      <TransitionContainer
        keyValue={challengesHistory}
        timeout={300}
        animation="route-animation"
      >
        <ChallengeContainer
          type={season.name}
          title="Kauden yhteisöhaasteet"
          subtitle={season.period}
          challenges={communalChallenges}
          loading={communalLoading}
          completed
        />
        <ChallengeContainer
          title="Omat haasteet"
          challenges={ownChallenges}
          loading={ownLoading}
          completed
        />
      </TransitionContainer>
    </TabPage>
  );
}
