import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
const { App: CapApp } = Plugins;

const AppUrlListener = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", data => {
      console.log("appUrlOpen", data);
      const slug = data.url.split(".app").pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AppUrlListener;
