import React, { useState, useEffect, useContext } from "react";
import { TabPage, TransitionContainer } from "../PageContainer/PageContainer";
import HistorySelector from "../HistorySelector/HistorySelector";
import AchievementDisplay from "../AchievementDisplay/AchievementDisplay";
import Ranking from "../Ranking/Ranking";
import { getYears } from "../../helpers/helpers";
import SecurityContext from "../Context/SecurityContext";

export default function OwnAchievements(props) {
  const { ownAchievementsHistory, setOwnAchievementsHistory } = props;
  const { api, user } = useContext(SecurityContext);

  const [rankingInOwnCommunity, setRankingInOwnCommunity] = useState([]);
  const [rankingInAllCommunities, setRankingInAllCommunities] = useState([]);
  const [rankingTop10, setRankingTop10] = useState([]);
  const [achievements, setAchievements] = useState({});
  const [loadingOwn, setLoadingOwn] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [loadingTop10, setLoadingTop10] = useState(false);

  const years = getYears();
  const year =
    ownAchievementsHistory > 0 ? years[ownAchievementsHistory] : null;

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      api
        .getUserStatistics(year)
        .then(data => {
          if (data) {
            setAchievements(data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return () => (ignore = true);
  }, [api, year]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && user) {
      setLoadingOwn(true);
      api
        .getLeadersOwnCommunity(year)
        .then(data => {
          if (data) {
            transformRankingData(data, user);
            setRankingInOwnCommunity(data);
          }
          setLoadingOwn(false);
        })
        .catch(err => {
          console.log(err);
          setLoadingOwn(false);
        });
    }
    return () => (ignore = true);
  }, [api, year, user]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && user) {
      setLoadingAll(true);
      api
        .getLeadersAllUsers(year)
        .then(data => {
          if (data) {
            transformRankingData(data, user);
            setRankingInAllCommunities(data);
          }
          setLoadingAll(false);
        })
        .catch(err => {
          console.log(err);
          setLoadingAll(false);
        });
    }
    return () => (ignore = true);
  }, [api, year, user]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && user) {
      setLoadingTop10(true);
      api
        .getLeadersAllUsersTop10(year)
        .then(data => {
          if (data) {
            transformRankingData(data, user);
            setRankingTop10(data);
          }
          setLoadingTop10(false);
        })
        .catch(err => {
          console.log(err);
          setLoadingTop10(false);
        });
    }
    return () => (ignore = true);
  }, [api, year, user]);

  return (
    <TabPage>
      <HistorySelector
        history={ownAchievementsHistory}
        setHistory={setOwnAchievementsHistory}
        historyItems={years}
        context="Suoritukset ajalta"
      />
      <TransitionContainer
        keyValue={ownAchievementsHistory}
        timeout={300}
        animation="route-animation"
      >
        <AchievementDisplay type="own" achievements={achievements} />
        <Ranking
          title="Sijoitus omassa yhteisössä"
          type="own"
          ranking={rankingInOwnCommunity}
          loading={loadingOwn}
        />
        <Ranking
          title="Sijoitus kaikissa yhteisöissä"
          type="own"
          ranking={rankingInAllCommunities}
          loading={loadingAll}
        />
        <Ranking
          title="Top 10"
          type="own"
          ranking={rankingTop10}
          loading={loadingTop10}
        />
      </TransitionContainer>
    </TabPage>
  );
}

function transformRankingData(data, user) {
  data.forEach(i => {
    if (user && i.scorer_id === user.id) {
      i.self = true;
    }
  });
  return data;
}
