import React, { useState, useEffect, Suspense } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";

import { Plugins } from "@capacitor/core";

import Api from "./api/Api";
import {
  getStorageToken,
  setStorageToken,
  removeStorageToken,
} from "./helpers/helpers";

import Home from "./screens/Home";
import Challenges from "./screens/Challenges";
import Achievements from "./screens/Achievements";
import Settings from "./screens/Settings";

import ResetPassword from "./components/ResetPassword/ResetPassword";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ChangeComplex from "./components/ChangeComplex/ChangeComplex";
import Note from "./components/Note/Note";
import Spinner from "./components/Spinner/Spinner";
import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import NavBar from "./components/NavBar/NavBar";
import AnimatedSwitch from "./components/AnimatedSwitch/AnimatedSwitch";
import SecurityContext from "./components/Context/SecurityContext";
import AppUrlListener from "./AppUrlListener";

import HomeOutlineIcon from "mdi-react/HomeOutlineIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import PodiumGoldIcon from "mdi-react/PodiumGoldIcon";
import SettingsOutlineIcon from "mdi-react/SettingsOutlineIcon";

import "./styles/global.scss";

const { SplashScreen } = Plugins;

function App() {
  const [splashHidden, setSplashHidden] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!splashHidden) {
      SplashScreen.hide().then(() => setSplashHidden(true));
    }
  });

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setLoading(true);
      getStorageToken()
        .then(res => {
          let val = null;
          if (res && res.accessToken) {
            val = Object.assign({}, res);
          }
          setTokenData(val);
          setLoading(false);
        })
        .catch(() => {
          setTokenData(null);
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, []);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setApi(new Api(tokenData));
    }
    return () => (ignore = true);
  }, [tokenData]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && tokenData) {
      api
        .validateToken()
        .then(({ data }) => {
          if (data && data.success) {
            console.log("token is valid");
            setUser(data.data);
          }
        })
        .catch(err => {
          if (err.message === "No access") {
            resetToken();
          }
        });
    }
    return () => (ignore = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  function updateToken(newTokenData) {
    setTokenData(newTokenData);
    setStorageToken("tokenData", newTokenData);
    //localStorage.setItem("tokenData", JSON.stringify(newTokenData));
  }

  function resetToken() {
    setTokenData(null);
    removeStorageToken();
    //localStorage.removeItem("tokenData");
  }

  return (
    <SecurityContext.Provider value={{ api, user }}>
      <Router>
        <AppUrlListener></AppUrlListener>
        <Switch>
          <Route path="/note">
            <Note />
          </Route>
          <Route
            path="/reset-password"
            component={props => <ResetPassword {...props} />}
          />
          {loading ? null : !tokenData ? (
            <Suspense fallback={<Spinner>Ladataan kirjautumista…</Spinner>}>
              <Redirect to="/" />
              <Login updateToken={updateToken} />
            </Suspense>
          ) : (
            <AppContainer resetToken={resetToken} />
          )}
        </Switch>
      </Router>
    </SecurityContext.Provider>
  );
}

function AppContainer(props) {
  const { resetToken } = props;
  const [challengesHistory, setChallengesHistory] = useState(0);
  const [ownAchievementsHistory, setOwnAchievementsHistory] = useState(0);
  const [
    communityAchievementsHistory,
    setCommunityAchievementsHistory,
  ] = useState(0);

  let location = useLocation();
  const pageContext = getPageContext(location.pathname);
  const navigationLinks = [
    { to: "/", title: "Koti", color: "green", icon: <HomeOutlineIcon /> },
    {
      to: "/challenges/open",
      title: "Haasteet",
      color: "blue",
      icon: <StarOutlineIcon />,
    },
    {
      to: "/achievements/own",
      title: "Suoritukset",
      color: "red",
      icon: <PodiumGoldIcon />,
    },
    {
      to: "/settings",
      title: "Asetukset",
      color: "white",
      icon: <SettingsOutlineIcon />,
    },
  ];

  return (
    <Suspense fallback={<Spinner>Ladataan sivua…</Spinner>}>
      <Switch>
        <Route exact path="/challenges">
          <Redirect to="/challenges/open" />
        </Route>
        <Route exact path="/achievements">
          <Redirect to="/achievements/own" />
        </Route>
      </Switch>
      <Header
        title={pageContext.title}
        color={pageContext.color}
        emblem={pageContext.emblem}
      />
      <MainContent>
        <AnimatedSwitch
          keySlice={[0, 9]}
          animation="route-animation"
          timeout={300}
        >
          <Route path="/challenges/open">
            <Challenges pageContext={pageContext} activeTabIndex={0} />
          </Route>
          <Route path="/challenges/completed">
            <Challenges
              pageContext={pageContext}
              activeTabIndex={1}
              challengesHistory={challengesHistory}
              setChallengesHistory={setChallengesHistory}
            />
          </Route>
          <Route path="/achievements/own">
            <Achievements
              pageContext={pageContext}
              activeTabIndex={0}
              ownAchievementsHistory={ownAchievementsHistory}
              setOwnAchievementsHistory={setOwnAchievementsHistory}
            />
          </Route>
          <Route path="/achievements/community">
            <Achievements
              pageContext={pageContext}
              activeTabIndex={1}
              communityAchievementsHistory={communityAchievementsHistory}
              setCommunityAchievementsHistory={setCommunityAchievementsHistory}
            />
          </Route>
          <Route exact path="/settings">
            <Settings pageContext={pageContext} resetToken={resetToken} />
          </Route>
          <Route path="/settings/change-password">
            <ChangePassword />
          </Route>
          <Route path="/settings/change-complex">
            <ChangeComplex />
          </Route>
          <Route path="/">
            <Home pageContext={pageContext} />
          </Route>
        </AnimatedSwitch>
      </MainContent>
      <NavBar items={navigationLinks} currentPage={pageContext.page} />
    </Suspense>
  );
}

const getPageContext = location => {
  if (location.slice(0, 11) === "/challenges") {
    return {
      page: "/challenges",
      title: "Haasteet",
      color: "blue",
      emblem: false,
    };
  } else if (location.slice(0, 13) === "/achievements") {
    return {
      page: "/achievements",
      title: "Suoritukset",
      color: "red",
      emblem: false,
    };
  } else if (location.slice(0, 9) === "/settings") {
    return {
      page: "/settings",
      title: "Asetukset",
      color: "white",
      emblem: false,
    };
  } else {
    return { page: "/", title: "EkoVAV", color: "green", emblem: true };
  }
};

export default App;
