import React, { useState, useEffect, useContext } from "react";

import PageContainer from "../components/PageContainer/PageContainer";
import ChallengeContainer from "../components/ChallengeContainer/ChallengeContainer";
import Feed from "../components/Feed/Feed";
import { getExtendedList } from "../components/Challenges/ChallengesHelper";
import SecurityContext from "../components/Context/SecurityContext";

const Home = () => {
  const { api } = useContext(SecurityContext);
  const [feed, setFeed] = useState({});
  const [loading, setLoading] = useState(false);
  const [dailyChallenge, setDailyChallenge] = useState({});
  const [dailyLoading, setDailyLoading] = useState(false);

  const dailyDate =
    dailyChallenge.data &&
    dailyChallenge.data[0] &&
    dailyChallenge.data[0].date;
  const dailyCompleted =
    dailyChallenge.data &&
    dailyChallenge.data[0] &&
    dailyChallenge.data[0].completed;

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setDailyLoading(true);
      api
        .getDailyChallenge()
        .then(response => {
          if (response && response.data) {
            const own = getExtendedList([response.data], "own");
            setDailyChallenge({ data: own, error: null });
          }
          setDailyLoading(false);
        })
        .catch(err => {
          console.log(err);
          setDailyChallenge({ data: null, error: "Tietojen haku epäonnistui" });
          setDailyLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setLoading(true);
      api
        .getFeed()
        .then(response => {
          if (response && response.data) {
            setFeed({ data: response.data, error: null });
          }
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setFeed({ data: null, error: "Tietojen haku epäonnistui" });
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <PageContainer>
      <ChallengeContainer
        type="daily"
        title="Päivän haaste"
        subtitle={dailyDate}
        challenges={dailyChallenge}
        loading={dailyLoading}
        dailyCompleted={dailyCompleted}
      />
      <Feed feed={feed} loading={loading} />
    </PageContainer>
  );
};

export default Home;
