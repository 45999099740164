import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./NavBar.module.scss";

const NavBar = ({ items, currentPage }) => {
  return (
    <div className={styles.navBar}>
      <div className={styles.container}>
        {items.map((item, index) => {
          const itemClassNames = classNames(styles.item, {
            [styles.green]: item.color === "green",
            [styles.blue]: item.color === "blue",
            [styles.red]: item.color === "red",
            [styles.white]: item.color === "white",
            [styles.active]: item.to.slice(0, 9) === currentPage.slice(0, 9),
          });

          return (
            <div className={styles.itemContainer} key={index}>
              <Link to={item.to} className={itemClassNames}>
                <div className={styles.iconContainer}>{item.icon}</div>
                <div className={styles.label}>{item.title}</div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavBar;
