import React from "react";
import classNames from "classnames";
import styles from "./ChallengeContainer.module.scss";

import SectionHeading from "../SectionHeading/SectionHeading";
import Challenge from "../Challenge/Challenge";

import { Snow, Flower, Sun, Leaf, HandLarge } from "../Symbols/Symbols";
import Spinner from "../Spinner/Spinner";

const ChallengeContainer = ({
  type,
  title,
  subtitle,
  challenges,
  completed,
  loading,
  dailyCompleted,
}) => {
  const challengeContainerClassNames = classNames(styles.challengeContainer, {
    [styles.standard]: type === "standard" || !type,
    [styles.winter]: type === "winter",
    [styles.spring]: type === "spring",
    [styles.summer]: type === "summer",
    [styles.autumn]: type === "autumn",
    [styles.daily]: type === "daily",
  });

  let icon = null;
  if (type === "winter") icon = <Snow />;
  if (type === "spring") icon = <Flower />;
  if (type === "summer") icon = <Sun />;
  if (type === "autumn") icon = <Leaf />;
  if (type === "daily") icon = <HandLarge />;

  return (
    <div className={challengeContainerClassNames}>
      <div className={styles.header}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {title && <SectionHeading>{title}</SectionHeading>}
        {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      </div>
      <div className={styles.challenges}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner}>Ladataan</Spinner>
          </div>
        ) : challenges.error ? (
          <div className={styles.textCenter}>{challenges.error}</div>
        ) : dailyCompleted ? (
          <div className={styles.textContainer}>
            <p>Päivän haaste suoritettu!</p>
          </div>
        ) : (
          challenges.data &&
          challenges.data.map((challenge, index) => {
            return (
              <Challenge
                key={index}
                id={challenge.id}
                type={type ? type : undefined}
                title={challenge.title}
                icon={challenge.icon}
                personalPoints={challenge.personalPoints}
                communalPoints={challenge.communalPoints}
                endDate={challenge.end_date}
                completionCount={challenge.completions}
                form={challenge.form}
                description={challenge.description}
                completed={completed}
                initAccepted={challenge.accepted}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default ChallengeContainer;
