import React from "react";
import { Route } from "react-router-dom";

import PageContainer from "../components/PageContainer/PageContainer";
import OwnAchievements from "../components/Achievements/OwnAchievements";
import CommunityAchievements from "../components/Achievements/CommunityAchievements";

const Achievements = ({
  pageContext,
  activeTabIndex,
  ownAchievementsHistory,
  setOwnAchievementsHistory,
  communityAchievementsHistory,
  setCommunityAchievementsHistory,
}) => {
  const tabs = {
    active: activeTabIndex,
    page: pageContext.page,
    color: pageContext.color,
    tabs: [
      {
        to: `${pageContext.page}/own`,
        label: "Omat suoritukset",
      },
      {
        to: `${pageContext.page}/community`,
        label: "Yhteisön suoritukset",
      },
    ],
  };

  return (
    <PageContainer tabs={tabs}>
      <Route exact path={`${pageContext.page}/own`}>
        <OwnAchievements
          ownAchievementsHistory={ownAchievementsHistory}
          setOwnAchievementsHistory={setOwnAchievementsHistory}
        />
      </Route>
      <Route exact path={`${pageContext.page}/community`}>
        <CommunityAchievements
          communityAchievementsHistory={communityAchievementsHistory}
          setCommunityAchievementsHistory={setCommunityAchievementsHistory}
        />
      </Route>
    </PageContainer>
  );
};

export default Achievements;
