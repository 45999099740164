export const complexSelectStyles = {
  control: styles => {
    return {
      ...styles,
      borderRadius: "0",
      boxShadow: "none",
      border: "2px solid #004d8f",
      marginBottom: "8px",
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    background: "transparent",
  }),
  placeholder: styles => ({
    ...styles,
    opacity: "1",
    color: "#5f6a82",
  }),
  menu: styles => ({
    ...styles,
    zIndex: "11",
    margin: 0,
  }),
  menuList: styles => ({
    ...styles,
    padding: 0,
    boxShadow: "none",
    border: "2px solid #004d8f",
    borderTop: "none",
  }),
  option: styles => ({
    ...styles,
    padding: "10px 14px",
    borderBottom: "1px solid #c0c5d1",
  }),
};
