import React from "react";
import styles from "./AchievementDisplay.module.scss";

import Tag from "../Tag/Tag";

import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import FlagVariantOutlineIcon from "mdi-react/FlagVariantOutlineIcon";

const AchievementDisplay = ({ type, achievements }) => {
  return (
    <div className={styles.achievementDisplay}>
      <div className={styles.nameContainer}>
        <h1 className={styles.name}>{achievements.scorer_name}</h1>
      </div>
      <div className={styles.tagContainer}>
        <div className={styles.tag}>
          <Tag
            icon={<StarOutlineIcon />}
            label="Pisteet"
            value={achievements.total_points}
            large
            blue={type === "own"}
            red={type === "communal"}
          />
        </div>
        <div className={styles.tag}>
          <Tag
            icon={<FlagVariantOutlineIcon />}
            label="Haasteita suoritettu"
            value={achievements.completed_challenges_count}
            large
            darkBlue
          />
        </div>
      </div>
    </div>
  );
};

export default AchievementDisplay;
