import React, { useContext, useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown/with-html";
import classNames from "classnames";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import styles from "./Feed.module.scss";

import Tag from "../Tag/Tag";
import Spinner from "../Spinner/Spinner";
import SecurityContext from "../Context/SecurityContext";

const Feed = ({ feed, loading }) => {
  return (
    <div className={styles.feed}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Spinner className={styles.spinner}>Ladataan</Spinner>
        </div>
      ) : feed.error ? (
        <div className={styles.textCenter}>{feed.error}</div>
      ) : (
        <>
          <div className={styles.timeline}>
            <div className={styles.timelineGradient} />
          </div>
          <div className={styles.items}>
            {feed.data &&
              feed.data.map((item, index) => {
                return <Item item={item} key={index} index={index} />;
              })}
          </div>
        </>
      )}
    </div>
  );
};

const Item = ({ item, index }) => {
  const { api } = useContext(SecurityContext);
  const [collapsed, setCollapsed] = useState(true);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const contentClassNames = classNames(styles.content, {
    [styles.collapsed]: collapsed,
  });
  const toggleClassNames = classNames(styles.toggle, {
    [styles.rotated]: !collapsed,
  });
  const date = getDate(item.sent_at);

  useEffect(() => {
    let ignore = false;
    if (!ignore && [0, 1, 2].includes(index) && !content && api) {
      setLoading(true);
      api
        .getFeedContent(item.id)
        .then(response => {
          if (response && response.data) {
            setContent({ data: response.data, error: null });
          }
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setContent({ data: null, error: "Tietojen haku epäonnistui" });
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api, index, item.id, content]);

  function editCollapse() {
    if (collapsed && !content && api) {
      setLoading(true);
      api
        .getFeedContent(item.id)
        .then(response => {
          if (response && response.data) {
            setContent({ data: response.data, error: null });
          }
          setLoading(false);
          setCollapsed(!collapsed);
        })
        .catch(err => {
          console.log(err);
          setContent({ data: null, error: "Tietojen haku epäonnistui" });
          setLoading(false);
        });
    } else {
      setCollapsed(!collapsed);
    }
  }

  return (
    <div className={styles.item}>
      <div className={styles.date}>{date}</div>
      <div className={styles.top}>
        <h1 className={styles.title}>{item.title}</h1>
        <div className={styles.tag}>
          {item.source_type === "Announcement" ? (
            <Tag darkBlue value="Heka-tiedote" />
          ) : (
            <Tag darkBlue value="Suoritus" />
          )}
        </div>
      </div>
      <Collapse
        isOpened={!collapsed}
        theme={{
          collapse: "ReactCollapse--feed",
          content: "ReactCollapse--content",
        }}
      >
        <div className={contentClassNames}>
          {loading ? (
            <Skeleton count={3} />
          ) : content && content.data ? (
            <ReactMarkdown source={content.data} escapeHtml={false} />
          ) : content && content.error ? (
            content.error
          ) : (
            <Skeleton count={3} duration={0} />
          )}
          {collapsed && <div className={styles.contentGradient} />}
        </div>
      </Collapse>
      <div className={styles.buttons}>
        <button className={toggleClassNames} onClick={() => editCollapse()}>
          <span>{collapsed ? "Näytä lisää" : "Näytä vähemmän"}</span>
          <ChevronDownIcon />
        </button>
      </div>
    </div>
  );
};

export default Feed;

function getDate(sent_at) {
  if (!sent_at) return "-";

  const capitalized = sent_at[0].toUpperCase() + sent_at.slice(1);
  const array = capitalized.split(".");
  const month = getMonth(array[1]);
  return `${array[0]}. ${month} ${array[2]}`;
}

function getMonth(string) {
  const num = parseInt(string);
  let month;
  switch (num) {
    case 1:
      month = "tammikuuta";
      break;
    case 2:
      month = "helmikuuta";
      break;
    case 3:
      month = "maaliskuuta";
      break;
    case 4:
      month = "huhtikuuta";
      break;
    case 5:
      month = "toukokuuta";
      break;
    case 6:
      month = "kesäkuuta";
      break;
    case 7:
      month = "heinäkuuta";
      break;
    case 8:
      month = "elokuuta";
      break;
    case 9:
      month = "syyskuuta";
      break;
    case 10:
      month = "lokakuuta";
      break;
    case 11:
      month = "marraskuuta";
      break;
    case 12:
      month = "joulukuuta";
      break;
    default:
      month = string;
  }
  return month;
}
