import { Plugins } from "@capacitor/core";

const { Storage, Device } = Plugins;

export const apiUrl = path => {
  const apiHost = process.env.REACT_APP_API_HOST;
  if (apiHost && path.slice(0, 6) !== "https:") {
    return `https://${apiHost}${path}`;
  } else {
    return path;
  }
};

async function getDeviceRedirectUrl(path, domain) {
  const info = await Device.getInfo();
  console.log(info);
  if (info && info.platform) {
    return `${
      info.platform === "ios" ? "ekovav" : "https"
    }://${domain}${path}`;
  } else {
    return `https://${domain}${path}`;
  }
}

export async function redirectUrl(path) {
  const currentEnv = process.env.REACT_APP_ENV;
  if (currentEnv && path.slice(0, 6) !== "https:") {
    if (currentEnv === "staging") {
      return await getDeviceRedirectUrl(
        path,
        "ecovav-staging.netlify.app"
      );
    } else if (currentEnv === "production") {
      return await getDeviceRedirectUrl(
        path,
        "ecovav-production.netlify.app"
      );
    } else {
      return window.location.origin + path;
    }
  } else {
    return path;
  }
}

export const getLocalStorage = key => {
  let value = null;
  const localStorageVal = localStorage.getItem(key);
  if (localStorageVal) {
    value =
      key === "filter_params" ? JSON.parse(localStorageVal) : localStorageVal;
  }
  return value;
};

function getStorageToken() {
  return Storage.get({ key: "tokenData" }).then(ret => {
    return ret && ret.value && JSON.parse(ret.value);
  });
}

async function setStorageToken(name, val) {
  await Storage.set({
    key: name,
    value: JSON.stringify(val),
  });
}

async function removeStorageToken() {
  await Storage.remove({ key: "tokenData" });
}

export { getStorageToken, setStorageToken, removeStorageToken };

/*export const getTokenData = () => {
  const data = getLocalStorage("tokenData");
  return data && JSON.parse(data);
};*/

export function queryParameters(search) {
  if (!search) {
    return {};
  }

  return search
    .slice(1)
    .split("&")
    .reduce((acc, segment) => {
      const pair = segment.split("=");
      acc[pair[0]] = pair[1];
      return acc;
    }, {});
}

export function getYears() {
  const current = new Date();
  const currentYear = current.getFullYear();
  let i = 2019;
  const arr = [i];
  while (i < currentYear) {
    arr.push(i + 1);
    i++;
  }
  return arr.reverse().map(y => y.toString());
}
